import { useLocation } from "react-router-dom";
import {useEffect} from "react";
import routes from "routes/routesList";
import smoothscroll from "smoothscroll-polyfill";

// kick off the polyfill!
smoothscroll.polyfill();

export const usePathname = () => {
  const location = useLocation();
  const {pathname} = location;
  
  useEffect(() => {
    //scroll to top on navigation
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    const path = routes?.find((e) => e.path.split("/")[1] === pathname.split("/")[1])?.path ?? "";
    //set document title on navigation
    if (pathname !== "/" || pathname !== "/home") {
      document.title = `Schooliply - ${pathname.charAt(1).toUpperCase()}${path.replace(/[^a-zA-Z0-9]/g, " ").substring(2)}`; 
    }
    if (pathname === "/" || pathname === "/home" ) {
      document.title = "Schooliply - Home";
    }
  }, [pathname]);
  
 
  return pathname;
};