import React from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.min.css";
import { Backdrop } from "component/Navbar/MobileMenu/styles";

function Index({ isOpen, onClose }) {
  return (
    <div>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="Q4ZNPhTKAFo"
        onClose={onClose}
      />
      {isOpen && <Backdrop />}
    </div>
  );
}

export default Index;
