import { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AppLayout from "./AppLayout";
import ErrorBoundary from "./component/ErrorBoundary";
import { FullPageSpinner } from "./component/Loaders";
import routes from "./routes/routesList";

function App() {
  return (
    <Router>
      <ErrorBoundary>
        <Suspense fallback={<FullPageSpinner />}>
          <AppLayout>
            <Switch>
              {routes.map(({ component, ...rest }, index) => (
                <Route component={component} {...rest} key={`app-route-${index}`} />
              ))}
            </Switch>
          </AppLayout>
        </Suspense>
      </ErrorBoundary>
    </Router>
  );
}

export default App;
