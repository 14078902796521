import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  .scroll-to-top {
    position: fixed;
    right: 1rem;
    bottom: 20px;
    background: transparent;
    font-size: 4rem;
    transition: all 2s ease-in-out;
    animation-duration: 0.9s;
    animation-name: slide-in;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    /* opacity: ; */
    @keyframes slide-in {
      0% {
        transform: translateY(100%);
        filter: blur(2px);
      }
      100% {
        transform: translateX(0);
        filter: blur(0);
      }
    }
  }
`;
