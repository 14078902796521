import styled from "styled-components";
import COLORS from "../../styles/colors";


export const Container = styled.div`
  height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      animation: flickerAnimation 2s linear infinite;
      color: ${COLORS.primaryBackground};
      position: absolute;
    }


  @keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-o-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-moz-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes flickerAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const ContainerInline =styled.div`
  display: inline-block;

  svg {
    animation: rotate 0.9s linear infinite;
    /* color: $secondary; */
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

`; 

