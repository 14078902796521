import React from "react";

export default function useScroll() {
  const [offset, setOffset] = React.useState({ x: 0, y: 0 });

  React.useEffect(() => {
    function trackScrollPosition() {
      setOffset({
        x: window.pageXOffset,
        y: window.pageYOffset
      });
    }

    window.addEventListener("scroll", trackScrollPosition);
    trackScrollPosition();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", trackScrollPosition);
  }, []);

  return offset;
}
