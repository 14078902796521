import React from "react";
import styled from "styled-components";
import COLORS from "styles/colors";


export default function MenuIcon(props) {
  // const { isMobileNavVisible, setIsMobileNavVisible, isHeaderDark } = props;
  const { isHeaderDark, setShowMenu, showMenu } = props;

  const onClick = e => {
    const target = e.currentTarget.className;
    if (target.includes("open")) {
      e.currentTarget.className = target.replace("open", "");
      setShowMenu(false);
    } else {
      e.currentTarget.className = target + " open";
      setShowMenu(true);
    }
  };
  return (
    <Container isHeaderDark={isHeaderDark}>
      <div className={`navMenuIcon ${!showMenu? "": "open"}`} onClick={(e) => onClick(e)}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </Container>
  );
}

const Container = styled.div`
  margin: 0;
  padding: 0;
  display: inline;
  cursor: pointer;

  .navMenuIcon {
    z-index: 2;
    width: 28px;
    display: inline-block;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
  }

  .navMenuIcon span {
    display: block;
    position: absolute;
    height: 3.3px;
    width: 100%;
    background: ${({ isHeaderDark }) => isHeaderDark ? COLORS.white : COLORS.black};
    border-radius: 4px;
    opacity: 1;
    left: 0;
    z-index: -111;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  .navMenuIcon span:nth-child(1) {
    top: 0px;
  }

  .navMenuIcon span:nth-child(2),
  .navMenuIcon span:nth-child(3) {
    top: 10px;
  }

  .navMenuIcon span:nth-child(4) {
    top: 20px;
  }

  .navMenuIcon.open span:nth-child(1) {
    top: 14px;
    width: 0%;
    left: 50%;
  }

  .navMenuIcon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .navMenuIcon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .navMenuIcon.open span:nth-child(4) {
    top: 14px;
    width: 0%;
    left: 50%;
  }
`;
