import { createGlobalStyle } from "styled-components";
import COLORS from "./colors";
import CabinetGrotesk from "./fonts/CabinetGrotesk-Regular.woff";
import CabinetGroteskBold from "./fonts/CabinetGrotesk-Bold.woff";
import CabinetGroteskThin from "./fonts/CabinetGrotesk-Thin.woff";

const size = {
  fold: "319px",
  mobile: "425px",
  tablet: "768px",
  laptop: "1025px",
  desktop: "1440px"
};

export const device = {
  fold: `(max-width: ${size.fold})`,
  mobile: `(min-width: ${size.mobile})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  desktop: `(min-width: ${size.desktop})`
};

const GlobalStyle = createGlobalStyle`
@font-face {
font-family: "Cabinet Grotesk";
src: local("CabinetGrotesk"),
 url(${CabinetGrotesk}) format("woff");
font-weight: normal;
}
@font-face {
font-family: "Cabinet Grotesk";
src: local("CabinetGrotesk"),
 url(${CabinetGroteskBold}) format("woff");
font-weight: bold;
}
/* @font-face {
font-family: "Cabinet Grotesk";
src: local("CabinetGrotesk"),
 url(${CabinetGroteskThin}) format("woff");
font-weight: thin;
} */

:root {
  --font-family: "Cabinet Grotesk", "Inter", sans-serif;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* outline-color: $tertiary; */
}

html {
  font-size: 50%;
  font-family: "Cabinet Grotesk", sans-serif;
  scroll-behavior: smooth;

    @media ${device.tablet} {
      font-size: 62.5% !important;
    }

    @media ${device.fold} {
      font-size: 50% !important;
    }
}

body {
  box-sizing: border-box;
  background-color: ${COLORS.white};
  overflow-x: hidden;
  width: 100%;
  height: 100vh;
  font-size: 1.4rem;
  animation-duration: .9s;
  animation-name: appear;
  animation-timing-function: ease-in-out;

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

a {
  text-decoration: none;
  transition: color 0.3s ease-in;
}

ul {
  list-style-type: none;
}

button {
  cursor: pointer;
  border: none;
  font-family: inherit;
   transition: .5s;
      :hover{
          transform: translateY(-3px);
      }
}

`;

export default GlobalStyle;
