import React from "react";
import { Container } from "./styles";
import Navbar from "component/Navbar";
import Footer from "component/Footer";
import { RiArrowUpCircleFill } from "react-icons/ri";
import useScroll from "hooks/useTrackScrollPosition";

const AppLayout = ({ children }) => {
  const offset = useScroll();
  const scrollBtn = offset?.y >= 1000;

  const scrollToTop = ()=>{
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <Container>
      <Navbar scrollPosition={offset?.y} />
      {children}
      <Footer />
      {scrollBtn && (
        <button className="scroll-to-top" onClick={scrollToTop}>
          <RiArrowUpCircleFill />
        </button>
      )}
    </Container>
  );
};

export default AppLayout;
