import styled from "styled-components";
import COLORS from "styles/colors";

export const Container = styled.div`
  width: 100%;
  /* height: 500px; */
  left: 0;
  top: 80px;
  background: #fff;
  z-index: 5;
  position: fixed;
  padding: 2rem;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0px 1px 20px rgba(218, 180, 255, 0.05), 0px 1px 15px rgba(0, 0, 0, 0.04);
  border-radius: 0px 0px 8px 8px;

  .mobile-nav {
    /* display: flex; */
    /* justify-content: flex-start; */
    /* align-items: center; */
    height: 50%;
    ul li {
      margin: 20px 28px;
      text-decoration: none;
      cursor: pointer;
      min-height: 48px;
      border-bottom: 1px solid #f5f5f5;
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      :last-of-type {
        border-bottom: none;
      }
      a {
        color: #000;
        /* color: ${COLORS.primaryBackground}; */
      }

      span {
        display: flex;
        align-items: center;
        /* color: ${COLORS.primaryBackground}; */
        justify-content: space-between;
        transition: all 0.5s ease-in-out;
        .icon-rotate {
          &.rotate {
            transform: rotate(-180deg);
          }
        }
      }

      .dropdown {
        li a {
          margin: 0 5px;
          font-size: 1.6rem;
          /* 
            border-left: 1px solid ${COLORS.lightPrimary};
            border-radius: 4px; 
            padding-left: 50px;
          */
          .menuIcons{
            display: flex;
            align-items:center;
            .text{
              margin-left:5px;
            }
          }
        }
      }
    }
  }

  .onboarding {
    /* width: 100%; */
    /* display: flex;
    justify-content: center; */
    margin-top: 5rem;

    ul li {
      margin: 20px 0;
      text-decoration: none;
      /* min-width: 100%; */
      border: 1px solid ${COLORS.primaryBackground};
      padding: 1.6rem 2.4rem;
      border-radius: 4px;
      cursor: pointer;
      text-align: center;
      .login {
        /* font-weight: 400; */
      }
      &.get-started {
        /* color: ${COLORS.white}; */
        /* font-weight: 400; */
        /* padding: 1.6rem 2.4rem; */
        margin: 20px 28px;
        background: ${COLORS.primaryBackground};


        a {
          color: ${COLORS.white};
        }
      }
    }
  }
`;
export const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
  position: fixed;
  backdrop-filter: blur(5px);
`;
