import styled from "styled-components";
import COLORS from "styles/colors";

export const Container = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: 0.5s;
  padding: 0 2rem;
  font-size: 1.6rem;
  position: fixed;
  z-index: 20;
  background: ${({ changeBG }) => (changeBG ? COLORS.white : "none")};
  box-shadow: ${({ changeBG }) => (changeBG ? "2px 3px 4px rgba(0,0,0,.1)" : "none")};
  svg,
  a {
    z-index: 2;
  }

  @media all and (max-width: 1095px) {
    font-size: 1.4rem;
    justify-content: space-between;
  }

  .display-flex{
    display:flex;
  }

  .align-items-center{
    align-items:center;
  }

  .display-flex-column{
    flex-direction: column;
  }

  .logo-desktop, .logo-mobile {
    position: relative;

    .byhumansquad {
      position: absolute;
      bottom: 15px;
      right: 5px;
      font-size: 0.8em;
      color: ${({ home, changeBG }) => (home && !changeBG ? COLORS.white : COLORS.primaryBackground)};
    }
  }

  .logo-mobile {
    .byhumansquad {
      font-size:1.1em;
    }
  }

  .mobile-menu-btn {
    z-index: 2;
    /* color: ${({ home, changeBG }) => (home && !changeBG ? COLORS.white : COLORS.black)}; */
    cursor: pointer;
    margin: 0 10px;
    margin-top: -4rem;
  }
  nav {
    /*margin: 0 5rem;*/
    z-index: 2;

    &.desktop {
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
    ul {
      display: flex;
      align-items: center;
      span {
        text-decoration: none !important;
        margin: 0 2.5rem;
        color: ${({ home, changeBG }) => (home && !changeBG ? COLORS.white : COLORS.black)};
        font-weight: 700;
        /* position: relative; */
        padding-bottom: 0.25rem;
        white-space: nowrap;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: all 0.5s ease-in-out;
        cursor: pointer;
        @media all and (max-width: 1195px) {
          margin: 1.4rem;
        }

        .icon-rotate {
          margin: 0;
          &.rotate {
            margin-top: -5px;
            transform: rotate(-180deg);
          }
        }
      }
      .dropdown {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        position: absolute;
        justify-content: space-between;
        /* flex-direction: column; */
        width: 727px;
        min-height: 301px;
        margin-top: 2.3rem;
        left: -100px;
        background: #ffffff;
        border-radius: 12px;
        /* border: 1px solid rgba(190, 144, 212, 1); */
        box-shadow: 0px 1px 20px rgba(218, 180, 255, 0.05), 0px 1px 15px rgba(0, 0, 0, 0.04);
        padding: 4.6rem 0rem;

        .dropdown-icon-tip {
          width: 0;
          height: 0;
          border-left: 30px solid transparent;
          border-right: 30px solid transparent;
          border-bottom: 33px solid #fff;
          position: absolute;
          top: -20px;
          left: 150px;
        }
        .dropdown-item {
          /* margin: 0.5rem 0; */
          /* border-radius: 4px; */
          /* background: ${COLORS.lightPrimary}; */
          transition: filter 300ms;
          padding: 0 6rem;
          width: 50%;
          min-height: 109px;
          :first-of-type,
          :nth-of-type(2) {
            border-bottom: 1px solid #f5f5f5;
            margin-bottom: 26px;
          }
          :first-of-type {
            border-right: 1px solid #f5f5f5;
          }
        
          :last-of-type {
            width: 100%;
          }
          :hover {
            filter: brightness(1.2);
          }
          .desc {
            font-family: Cabinet Grotesk;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            margin-left: 45px;
            color: #6a6a6a;
            max-width: 203px;
            margin-top: 0;
            cursor: pointer;
            white-space: normal;
          }
        }
        li a {
          color: #000;
          font-family: Cabinet Grotesk;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          margin: 0;
          svg {
            margin-right: 1.5rem;
          }
          ::before {
            display: none;
          }
        }
      }
      li a {
        text-decoration: none !important;
        margin: 0 2.5rem;
        color: ${({ home, changeBG }) => (home && !changeBG ? COLORS.white : COLORS.black)};
        font-weight: 700;
        position: relative;
        padding-bottom: 0.25rem;
        white-space: nowrap;
        display: flex;
        align-items: center;
        @media all and (max-width: 1195px) {
          margin: 1.4rem;
        }
        &.login {
          margin-right: 1rem;

          color: ${({ home, changeBG }) =>
    home && !changeBG ? COLORS.white : COLORS.primaryBackground};
        }
      }
    }
  }
  nav ul li .get-started {
    color: ${({ home, changeBG }) => (home && !changeBG ? COLORS.primaryBackground : COLORS.white)};
    font-weight: 400;
    background: ${({ home, changeBG }) =>
    home && !changeBG ? COLORS.white : COLORS.primaryBackground};
    padding: 1.6rem 2.4rem;
    border-radius: 4px;
    margin-left: 1rem;
    ::before {
      display: none;
    }
  }

  nav ul li a {
    ::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0;
      height: 1px;
      background: ${({ home, changeBG }) =>
    home && !changeBG ? COLORS.white : COLORS.primaryBackground};
      transition: width 0.35s ease-out;
    }
    :hover {
      ::before {
        width: 60%;
      }
    }
  }
`;
