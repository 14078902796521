import styled from "styled-components";
import COLORS from "styles/colors";

export const Container = styled.div`
  width: 100%;
  /* border: 1px solid red; */
  .footerLogoLink {
    position:relative;
    margin-left:-10px;

    .byhumansquad {
      position: absolute;
      bottom: 15px;
      right: 5px;
      font-size: 0.8em;
      color: ${COLORS.primaryBackground};
    }
  }
  .row {
    /* border: 1px solid blue; */
    margin: 10rem 9rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    

    .col {
      /* border: 1px solid red; */
      a .logo {
        display: inline;
        margin: 200px;
      }
      .icons {
        display: flex;
        align-items: center;
        /* margin-top: 5px; */

        svg {
          margin-right: 3rem;
          margin-bottom: 18px;
        }
      }
    }
    .col1 {
      margin-bottom: 20px;
    }
    .col2 {
      display: flex;
      flex-wrap: wrap;
      /* align-items: center; */
      .col {
        margin: 0 5.6rem;
        .head {
          font-size: 1.8rem;
          line-height: 28px;
          font-weight: 700;
          margin-bottom: 18px;
          display: inline-block;
        }
        ul li {
          /* text-decoration: none !important; */
          margin-bottom: 24px;
          font-size: 1.6rem;
          color: #3d3d3d;
          white-space: nowrap;
          cursor: pointer;

          :hover {
            /* font-weight: bold; */
            text-decoration: underline;
          }
          a {
            /* text-decoration: none !important; */
            color: ${COLORS.black};
            width: 130px;
            display: block;
          }
        }
        :last-child {
          margin-right: 0;
        }
        :first-child {
          margin-left: 0;
        }
        @media all and (max-width: 1024px) {
          margin-left: 0;
        }
      }
    }
    @media all and (max-width: 768px) {
      margin: 5rem 2rem;
    }

    .copyright {
      @media all and (max-width: 768px) {
        display: none;
      }
      &.mobile {
        display: none;
        @media all and (max-width: 768px) {
          display: block;
          width: 100%;
          font-size: 16px;
          text-align: center;
          color: #3d3d3d;
          margin: 4rem 0;
        }
      }
    }
  }
`;
