import {ReactComponent as RecruitersIcon} from "assets/recruiters-dropdown.svg";
import {ReactComponent as SchoolsIcon} from "assets/schools-dropdown.svg";
import {ReactComponent as StudentsIcon} from "assets/students-dropdown.svg";
export const links = [
  {
    link: "/how-it-works",
    name: "How it Works"
  },
  {
    link: "/products",
    name: "Products",
    dropdown_item: [
      {
        item: "Students",
        url: "/students",
        icon: <StudentsIcon />,
        desc: "Get quality education. Apply to schools abroad.",
      },
      {
        item: "Scouts",
        url: "/scouts",
        icon: <RecruitersIcon />,
        desc: "Help your students get admission while earning."
      },
      {
        item: "Schools",
        url: "/schools",
        icon: <SchoolsIcon />,
        desc: "Increase your international student quota with qualified students."
      }
    ]
  },
  {
    link: "/about_us",
    name: "About Us"
  },
  {
    link: "/blog",
    name: "Blog"
  },
  /*{
    link: "/community",
    name: "Community"
  },*/
  {
    link: "/educationsummit",
    name: "Events"
  },
  {
    link: "https://api.campusboard.co.uk/partner_leads/new?partner=k8y33z96y4",
    name: "Accommodation Services",
    external: true
  }
];
