import { lazy } from "react";
import { Redirect } from "react-router-dom";
const Home = lazy(() => import("pages/Home"));
const Students = lazy(() => import("pages/Students"));
const Schools = lazy(() => import("pages/Schools"));
const Scouts = lazy(() => import("pages/Scouts"));
const FAQs = lazy(() => import("pages/FAQs"));
const TermsAndConditions = lazy(() => import("pages/TermsAndConditions"));
const ContactUs = lazy(() => import("pages/ContactUs"));
const PrivacyPolicy = lazy(() => import("pages/PrivacyPolicy"));
const CommunityGuidelines = lazy(() => import("pages/CommunityGuidelines"));
const Community = lazy(() => import("pages/Community"));
const Careers = lazy(() => import("pages/Careers"));
const JobDetails = lazy(() => import("pages/Careers/sections/JobDetails"));
const About = lazy(() => import("pages/About"));
const Blog = lazy(() => import("pages/Blog"));
const SingleArticle = lazy(() => import("pages/Blog/SinglePost"));
const EducationSummit = lazy(() => import("pages/EducationSummit"));

const routes = [
  {
    path: "/",
    exact: true,
    component: Home
  },
  {
    path: "/how-it-works",
    exact: true,
    component: () => <Redirect to="/#how-it-works" />
  },
  {
    path: "/students",
    exact: true,
    component: Students
  },
  {
    path: "/schools",
    exact: true,
    component: Schools
  },
  {
    path: "/scouts",
    exact: true,
    component: Scouts
  },
  {
    path: "/faqs",
    exact: true,
    component: FAQs
  },
  {
    path: "/contact_us",
    exact: true,
    component: ContactUs
  },
  {
    path: "/privacy_policy",
    exact: true,
    component: PrivacyPolicy
  },
  {
    path: "/terms_and_conditions",
    exact: true,
    component: TermsAndConditions
  },
  {
    path: "/community_guidelines",
    exact: true,
    component: CommunityGuidelines
  },
  {
    path: "/community",
    exact: true,
    component: Community
  },
  {
    path: "/educationsummit",
    exact: true,
    component: EducationSummit
  },
  {
    path: "/careers",
    exact: true,
    component: Careers
  },
  {
    path: "/careers/:id",
    exact: true,
    component: JobDetails
  },
  {
    path: "/about_us",
    exact: true,
    component: About
  },
  {
    path: "/blog",
    exact: true,
    component: Blog
  },
  {
    path: "/blog/:id",
    exact: true,
    component: SingleArticle
  },
  {
    path: "*",
    exact: true,
    component: () => <Redirect to="/" />
  }
];

export default routes;
