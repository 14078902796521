import React from "react";
import styled from "styled-components";
import Broken from "assets/broken.png";


const ErrorPage = () => {
  return (
    <Container>
      <img src={Broken} alt="broken_img" />
      Oops! Something went wrong!{" "}
      <span onClick={() => window.location.reload()} className="refresh">
        Please refresh!
      </span>
    </Container>
  );
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      info: null,
    };
  }

  componentDidCatch(error, info) {
    this.setState({ error, info });
  }

  render() {
    const { error } = this.state;

    if (error) {
      return <ErrorPage />;
    }
    return this.props.children;
  }
}

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 4rem);
  font-size: 1.4rem;
  img{
    width: 50px;
    height: auto;
    margin: 5px;
  }
  .refresh {
    color: red;
    cursor: pointer;
    text-decoration: underline;
  }
  h2 {
    font-size: 10rem;
  }
`;

export default ErrorBoundary;
